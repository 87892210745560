.data-grid-height {
    height: 1020px !important;
}
.form-group-item {
    font-weight: 400;
    color: rgb(115, 82, 199);
    margin: 0 5%;
}
.form-group-item-margin {
    margin: 0 5%;
}
.dx-field-item-label {
    text-align: right;
}
table,
th,
td {
    border: 1px solid #d3d3d3;
}
th {
    background-color: #ffffff;
}

tr:nth-child(odd):not(.dx-datagrid-headers tr) {
    background-color: #f2f2f2;
}

tr:nth-child(even) {
    background-color: #ffffff;
}

.dx-item-content.dx-list-item-content {
    background-color: #ffffff;
}

.dx-item-content.dx-list-item-content:hover {
    background-color: #f5f7fa;
}

.wweUc.horizontal {
    margin-left: 15%;
}
.dx-row.dx-freespace-row.dx-column-lines {
    height: 0px !important;
}
.dx-item.dx-radiobutton {
    display: inline-table;
    margin-right: 2%;
}

@media (max-width: 600px) {
    .dx-field-item-label {
        text-align: left;
    }
}
@media screen and (min-width: 300px) {
    .grid-filter-parent {
        text-align: center;
        justify-content: center;
    }
    .grid-filter-Button {
        margin-bottom: 10px;
    }
}
@media screen and (min-width: 1024px) {
    .grid-filter-parent {
        text-align: left;
        justify-content: left;
    }
}

.DropDownBoxClass {
    width: 100%;
    position: relative;
    left: 0;
}

@media (max-width: 600px) {
    .DropDownBoxClass {
        width: 353px;
        left: -45px;
    }
}
